body *{
	font-family: 'Nunito', sans-serif;
}
	
/* --- general --- */
.clearfix:after
{
	font-size: 0px;
	content: ".";
	display: block;
	height: 0px;
	visibility: hidden;
	clear: both;
}
body
{
	background: #FFF;
	padding: 0px;
	margin: 0px;
	/*font-family: 'Bariol';*/
	font-size: 16px;
	color: #444;
}
a
{
	text-decoration: none;
	outline: none;
}
a:hover
{
	text-decoration: underline;
}
a img
{
	display: block;
	transition: opacity 0.3s ease 0s;
	opacity: 1;
	max-width: 100%;
	height: auto;
}
a:hover img
{
	opacity: 0.8;
}
p
{
	padding: 1em 0;
	color: #606060;
	line-height: 150%;
}
p.description
{
	/*font-family: 'Raleway';*/
	font-size: 16px;
	line-height: 26px;
	padding: 0;
	margin-top: 34px;
}
p.description.t1
{
	/*font-family: 'Raleway';*/
	font-size: 14px;
	line-height: 24px;
	margin-top: 24px;
}
p a,
table a
{
	color: #F4BC16;
	font-weight: 600;
}
strong
{
	color: #000;
}
blockquote
{
	text-align: center;
	/*font-family: 'Raleway';*/
	font-size: 16px;
	color: #444;
	line-height: 26px;
}
blockquote:before
{
	display: block;
	font-size: 48px;
	color: #F4BC16;
	text-align: left;
	margin-bottom: 10px;
}
pre
{
	/*font-family: 'Courier new';*/
	font-size: 14px;
	line-height: 170%;
	background: url('../images/code_background.png') repeat;
	text-shadow: 0 1px 0 #FFF;
	zoom:1;
	filter: dropshadow(color=#ffffff, offx=0, offy=1);
	padding: 0 5px;
	margin: 0;
	overflow: auto;
}
label
{
	color: #25282A;
	/*font-family: 'Raleway';*/
}
.relative
{
	position: relative;
}
.responsive
{
	display: block;
	width: 100%;
	height: auto;
}
input, textarea
{
	/*font-family: 'Raleway';*/
	border: 1px solid #E2E6E7;
	font-size: 14px;
	line-height: 24px;
	padding: 12px 15px;
	color: #444;
	margin: 0;
}
input[type='number']
{
	appearance: textfield;
	-moz-appearance: textfield;
	-webkit-appearance: textfield;
	-webkit-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
{
	-webkit-appearance: none;
}
.radius
{
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}
img.radius
{
	max-width: 100%;
}
.image-wrapper
{
	display: inline-block;
	max-width: 100%;
	min-width: 20px;
	height: auto;
}
.float
{
	float: left;
}
.align-center
{
	text-align: center;
}
.align-left
{
	text-align: left;
}
.align-right
{
	text-align: right;
}
.padding-left-right-100
{
	padding: 0 100px;
}
.padding-bottom-17
{
	padding-bottom: 17px;
}
.padding-bottom-50
{
	padding-bottom: 50px;
}
.padding-bottom-61
{
	padding-bottom: 61px;
}
.padding-bottom-66
{
	padding-bottom: 66px;
}
.padding-bottom-70
{
	padding-bottom: 70px;
}
.padding-bottom-87
{
	padding-bottom: 87px;
}
.padding-top-7
{
	padding-top: 7px;
}
.padding-top-54
{
	padding-top: 54px;
}
.padding-top-70
{
	padding-top: 70px;
}
.padding-top-bottom-30
{
	padding: 30px 0;
}
.margin-top-0
{
	margin-top: 0 !important;
}
.margin-top-10
{
	margin-top: 10px !important;
}
.margin-top-20
{
	margin-top: 20px;
}
.margin-top-26
{
	margin-top: 26px;
}
.margin-top-30
{
	margin-top: 30px;
}
.margin-top-34
{
	margin-top: 34px !important;
}
.margin-top-40
{
	margin-top: 40px !important;
}
.margin-top-67
{
	margin-top: 67px;
}
.margin-top-70
{
	margin-top: 70px !important;
}
.nounderline,
.nounderline:hover
{
	text-decoration: none;
}
.top-border
{
	border-top: 1px solid #E2E6E7;
}
/* --- site container --- */
.site-container
{
	background: #FFF;
}
.site-container.boxed
{
	max-width: 1310px;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.10);
	-moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.10);
	-webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.10);
}
body.overlay .site-container
{
	position: relative;
	z-index: 2;
}
.flex-box
{
	display: block;
	display: flex;
	display: -ms-flexbox;
	display: -webkit-flexbox;
	display: -webkit-flex;
}
.flex-hide
{
	display: none;
	width: 100%;
	max-width: 100%;
	height: auto;
}
.flex-box .column
{
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
@media all and (min-width:0\0) and (min-resolution: .001dpcm)
{
	.flex-hide
	{
		display: inline;
	}
	.flex-hide.wpb_single_image img
	{
		width: 100%;
		height: auto;
	}
}
/* --- headers --- */
h1, h2, h3, h4, h5, h6
{
	font-family: 'Bariol-b';
	margin: 0;
	padding: 0;
	/*color: #606060;*/
	font-weight: 600;
	letter-spacing: 1px
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a
{
	color: #606060;
	transition: opacity 0.3s ease 0s;
	opacity: 1;
}
h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover
{
	opacity: 0.6;
	text-decoration: none;
}
h1
{
	font-size: 22px;
	line-height: 34px;
}
h2
{
	font-size: 29px;
	line-height: 40px;
}
h3
{
	font-size: 22px;
	line-height: 34px;
}
h4
{
	font-size: 18px;
	line-height: 28px;
}
h5
{
	font-size: 14px;
	line-height: 24px;
	font-weight: 700;
}
h6
{
	font-size: 15px;
	line-height: 24px;
	font-weight: 500;
	letter-spacing: normal;
}
h2.box-header
{
	text-align: center;
}
h2.box-header.align-left
{
	text-align: left;
}
.box-header:after
{
	display: block;
	content: "";
	background: #F4BC16;
	width: 40px;
	height: 2px;
	margin-top: 13px;
}
h2.box-header:after
{
	width: 80px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
}
h2.box-header.align-left:after
{
	margin-left: 0;
}
h3 .more
{
	margin-left: 50px;
}
h6.box-header
{
	border-bottom: 1px solid #E2E6E7;
}
h6.box-header:after
{
	margin-bottom: -1px;
}
/* --- header --- */
.header-container
{
	padding: 20px 0;
}
.header-top-bar-container
{
	background-color: #F5F5F5;
}
.header-top-bar-container .header-toggle
{
	display: none;
	clear: both;
	text-align: center;
	color: #444;
	padding: 15px 0;
}
.header-top-bar-container .header-toggle:hover
{
	color: #000;
}
.header-top-bar-container .header-toggle
{
	-moz-transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
	-o-transform: rotate(180deg);
	transform: rotate(180deg);
	-ms-filter: fliph;
	filter: fliph;
}
.header-top-bar-container .header-toggle.active
{
	-moz-transform: rotate(0);
	-webkit-transform: rotate(0);
	-o-transform: rotate(0);
	transform: rotate(0);
}
.header-top-bar,
.header
{
	width: 1170px;
	margin-left: auto;
	margin-right: auto;
}
.header .logo
{
	width: 370px;
	/*float: left;*/
}
.header h1
{
	font-size: 37px;
	font-weight: 900;
	color: #F4BC16;
	line-height: 1;
}
.header h1 a
{
	color: #F4BC16;
}
.header h1 a:hover
{
	opacity: 1;
}
/* --- page header --- */
.page-header
{
	height: 100px;
	-webkit-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05) inset;
	-moz-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05) inset;
	box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05) inset;
}
.page-header-left
{
	float: left;
	width: 400px;
}
.page-header-right
{
	float: right;
	width: 650px;
}
.page-header-right label
{
	float: left;
}
/* --- bread crumbs --- */
.bread-crumb-container
{
	float: right;
	padding: 7px 0;
}
.bread-crumb
{
	float: left;
}
.bread-crumb li
{
	float: left;
	margin-left: 3px;
}
.bread-crumb-container label,
.bread-crumb li,
.bread-crumb li a
{
	font-family: 'Raleway';
	font-weight: 500;
	font-size: 13px;
	color: #999;
	line-height: 20px;
}
.bread-crumb li a
{
	color: #444;
}
/* --- menu --- */
.menu-container
{
	width: 800px;
	/*float: right;*/
}
.menu-container nav
{
	float: right;
}
.header-container.sticky.move
{
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1001;
	background: #FFF;
	padding: 16px 0;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}
.boxed .header-container.sticky.move
{
	max-width: 1310px;
	width: 100%;
}
.sf-menu
{
	float: none;
	padding: 0;
	margin: 0;
}
.sf-menu li
{
	border: none;
	overflow: hidden;
}
.sf-menu li, .sf-menu li:hover, .sf-menu li.sfHover, .sf-menu a:focus, .sf-menu a:hover, .sf-menu a:active
{
	background: none #fff;
}
.sf-menu li:hover a, .sf-menu li:hover a:visited
{
	color: #F4BC16;
}
.sf-menu li a, .sf-menu li a:visited
{
	font-family: 'Bariol';
	font-size: 1rem;
	font-weight: 400;
	line-height: 24px;
	color: #444444;
	padding: 0.5rem 1.5rem;
	border: none;
}
.sf-menu a:hover,
.sf-menu a:visited:hover,
.sf-menu li.selected a
{
	color: #F4BC16;
}
.sf-menu .demo-Link {
	border: 1px solid #66e766;
	border-radius: 34px;
	background-color: #66e766;
}
body .header-container .menu-container .sf-menu>li:first-child>a
{
	padding-left: 0;
}
.sf-menu li ul
{
	border: 1px solid #E2E6E7;
}
.sf-menu li ul
{
	width: 218px;
}
.sf-menu li:hover, .sf-menu li.sfHover
{
	overflow: visible;
}
.sf-menu li:hover ul, .sf-menu li.sfHover ul
{
	top: 48px;
	left: 5px;
}
.sf-menu li.left-flyout:hover ul, .sf-menu li.left-flyout.sfHover ul
{
	left: auto;
	right: 0;
}
.sf-menu>li:first-child:hover ul, .sf-menu>li:first-child.sfHover ul
{
	left: -21px;
}
.sf-menu li ul li
{
	width: 218px;
	height: auto;
	padding: 0;
	border: none;
}
.menu-container .sf-menu li ul li a,
.menu-container .sf-menu li.selected ul li a,
.menu-container .sf-menu li:hover ul li a
{
	color: #444;
	border-top: 1px solid #E2E6E7;
	background-color: #FFF;
	padding: 13px 20px;
	background-image: none;
}
.menu-container .sf-menu li ul li:first-child a
{
	border-top: none;
}
.menu-container .sf-menu li ul li a:hover,
.menu-container .sf-menu li ul li.selected a,
.menu-container .sf-menu li:hover ul li.selected a,
.menu-container .sf-menu li:hover ul li.selected ul li a:hover,
.menu-container .sf-menu li:hover ul li ul li.selected a,
.menu-container .sf-menu li:hover ul li.selected ul li.selected a,
.menu-container .sf-menu li:hover ul li.sfHover>a
{
	color: #F4BC16;
}
ul.sf-menu li li:hover ul, ul.sf-menu li li.sfHover ul,
ul.sf-menu li li li:hover ul, ul.sf-menu li li li.sfHover ul
{
	left: 218px;
	z-index: 2 !important;
}
ul.sf-menu li li:first-child:hover ul, ul.sf-menu li li.sfHover:first-child ul,
ul.sf-menu li li:first-child li:first-child:hover ul, ul.sf-menu li li:first-child li.sfHover:first-child ul
{
	top: -1px;
}
/* --- mobile menu --- */
.mobile-menu-container
{
	display: none;
}
.header .mobile-menu-switch,
.mobile-menu,
.mobile-menu-divider
{
	display: none;
	margin-left: auto;
	margin-right: auto;
}
.mobile-menu-divider
{
	margin-top: 15px;
}
.mobile-menu li a
{
	display: block;
	color: #25282A;
	background: #F0F0F0;
	font-size: 14px;
	font-family: "Raleway";
	line-height: 24px;
	padding: 12px 20px;
	margin-bottom: 1px;
}
.mobile-menu li.selected a,
.mobile-menu li.selected ul li.selected a,
.mobile-menu li.selected ul li.selected ul li.selected a
{
	background: #F4BC16;
	color: #FFF;
}
.mobile-menu li ul a
{
	font-size: 14px;
	padding-left: 40px;
}
.mobile-menu li ul ul a
{
	font-size: 13px;
	padding-left: 80px;
}
.mobile-menu li.selected ul a,
.mobile-menu li.selected ul li.selected ul a
{
	color: #25282A;
	background: #F0F0F0;
}
.mobile-menu-switch .line
{
	display: block;
	width: 30px;
	height: 3px;
	background: #444;
	margin-top: 6px;
}
.mobile-menu-switch .line:first-child
{
	margin-top: 0;
}
.mobile-menu-switch:hover .line
{
	background: #F4BC16;
}
/* --- vertical menu --- */
.vertical-menu li
{
	margin-top: 10px;
}
.vertical-menu li:first-child
{
	margin-top: 0;
}
.vertical-menu li a
{
	display: block;
	background: #F5F5F5;
	padding: 18px 18px 18px 19px;
	font-family: 'Raleway';
	line-height: 24px;
	color: #444;
}
.vertical-menu li.selected a,
.vertical-menu li:hover a
{
	background: #F4BC16;
}
.vertical-menu li.selected a,
.vertical-menu li:hover a,
.vertical-menu li.selected a span,
.vertical-menu li:hover a span
{
	color: #FFF;
}
.vertical-menu li a span
{
	float: right;
	color: #AAA;
	font-size: 11px;
}
/* --- body styles --- */
body.image-1
{
	background: url("../images/backgrounds/images/image_1.jpg") fixed no-repeat center 0;
}
body.image-2
{
	background: url("../images/backgrounds/images/image_2.jpg") fixed no-repeat center 0;
}
body.image-3
{
	background: url("../images/backgrounds/images/image_3.jpg") fixed no-repeat center 0;
}
body.image-4
{
	background: url("../images/backgrounds/images/image_4.jpg") fixed no-repeat center 0;
}
body.image-5
{
	background: url("../images/backgrounds/images/image_5.jpg") fixed no-repeat center 0;
}
body.pattern-1
{
	background: url("../images/backgrounds/patterns/pattern_1.png") fixed;
}
body.pattern-2
{
	background: url("../images/backgrounds/patterns/pattern_2.png") fixed;
}
body.pattern-3
{
	background: url("../images/backgrounds/patterns/pattern_3.png") fixed;
}
body.pattern-4
{
	background: url("../images/backgrounds/patterns/pattern_4.png") fixed;
}
body.pattern-5
{
	background: url("../images/backgrounds/patterns/pattern_5.png") fixed;
}
body.pattern-6
{
	background: url("../images/backgrounds/patterns/pattern_6.png") fixed;
}
body.pattern-7
{
	background: url("../images/backgrounds/patterns/pattern_7.png") fixed;
}
body.pattern-8
{
	background: url("../images/backgrounds/patterns/pattern_8.png") fixed;
}
body.pattern-9
{
	background: url("../images/backgrounds/patterns/pattern_9.png") fixed;
}
body.pattern-10
{
	background: url("../images/backgrounds/patterns/pattern_10.png") fixed;
}
body.overlay .background-overlay
{
	display: block;
}
.background-overlay
{
	position: fixed;
	display: none;
	width: 100%;
	height: 100%;
	top: 0;
	background: url("../images/backgrounds/images/overlay.png");
	z-index: 1;
}
/* --- page --- */
.theme-page
{
}
.page-margin-top
{
	margin-top: 50px;
}
.page-margin-top-section
{
	margin-top: 65px;
}
.page-padding-top
{
	padding-top: 50px;
}
.page-padding-top-section
{
	padding-top: 65px;
}
/* --- vertical align --- */
.vertical-align-table
{
	display: table;
}
.vertical-align
{
	display: table-row;
}
.vertical-align-cell
{
	display: table-cell;
	vertical-align: middle;
}
/* --- page layout --- */
.row
{
	/*width: 1170px;*/
	margin-left: auto;
	margin-right: auto;
}
.column .row
{
	width: auto;
}
.row.full-width
{
	width: 100%;
}
.row.gray
{
	background: #F5F5F5;
}
.row.yellow
{
	background: #F4BC16;
}
.row:after
{
	font-size: 0px;
	content: ".";
	display: block;
	height: 0px;
	visibility: hidden;
	clear: both;
}
.column
{
	position: relative;
	float: left;
	margin-left: 30px;
}
.column:first-child,
.column.first,
.row.full-width>.column.column-1-2 .column-1-3:first-child
{
	margin-left: 0;
}
.column-1-1
{
	width: 100%;
}
.column-1-2
{
	width: 570px;
}
.row.full-width>.column-1-2
{
	width: 50%;
}
.row.full-width>.column-1-3
{
	width: 33%;
}
.row.full-width>.column-1-2 .column-1-3
{
	width: 30%;
}
.row.full-width>.column.column-1-2,
.row.full-width>.column.column-1-3
{
	margin: 0;
}
.row.full-width>.column.column-1-2 .column-1-3
{
	margin-left: 5%;
}
.column-1-3,
.column-2-3 .column-1-2
{
	width: 370px;
}
.column-2-3
{
	width: 770px;
}
.column-1-3 .column-1-2
{
	width: 150px;
}
.column-2-3 .column-1-3
{
	width: 210px;
}
.column-1-4,
.column-1-2 .column-1-2
{
	width: 270px;
}
.column-3-4
{
	width: 870px;
}
.column-3-4 .column-1-2
{
	width: 420px;
}
.column-right
{
	float: right;
}
.columns.no-width .column-left,
.columns.no-width .column-right
{
	width: auto;
}
/* --- services --- */
.services-list li,
.team-box
{
	position: relative;
	float: left;
	width: 390px;
	background: #FFF;
	padding-bottom: 26px;
	overflow: hidden;
}
div.team-box
{
	width: 100%;
}
.team-box
{
	padding-bottom: 0;
	background: transparent;
}
.services-list.services-icons li
{
	width: 570px;
	padding: 40px 40px 36px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
.services-list.services-items-border.services-icons li
{
	border: 1px solid #E2E6E7;
}
.services-list.services-icons li:nth-child(even)
{
	margin-left: 30px;
}
.services-list li .service-icon::before
{
	float: left;
	font-size: 48px;
	color: #F4BC16;
	border: 1px solid #E2E6E7;
	padding: 19px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}
.services-list.services-icons li .service-content
{
	margin-left: 128px;
}
.services-list li h4
{
	margin-top: 22px;
	text-align: center;
}
.team-box h4
{
	text-align: center;
}
.services-list li h4.box-header::after,
.team-box h4.box-header::after
{
	margin-top: 23px;
	margin-left: auto;
	margin-right: auto;
}
.team-box h4 span
{
	display: block;
	font-weight: 500;
	font-size: 11px;
	color: #999;
	line-height: 21px;
	letter-spacing: 1px;
	padding-top: 7px;
}
.services-list li p,
.team-box p
{
	text-align: center;
	font-family: 'Raleway';
	font-size: 14px;
	line-height: 24px;
	padding: 24px 25px 0;
	margin: 0;
}
.services-list.services-icons li h4
{
	margin-top: -8px;
}
.services-list.services-icons li h4,
.services-list.services-icons li p
{
	text-align: left;
}
.services-list.services-icons li p
{
	padding: 24px 0 0;
}
.services-list.services-icons li h4.box-header::after
{
	margin-left: 0;
	margin-right: 0;
}
.services-list a>.re-preloader,
.team-box a>.re-preloader
{
	height: 260px;
}
.services-list img,
.team-box img
{
	max-width: 100%;
	height: auto;
}
.team-box ul.social-icons
{
	display: none;
	position: absolute;
	background: transparent;
}
.team-box ul.social-icons.social-static
{
	position: static;
	display: block;
	background: #FFF;
}
.team-box:hover ul.social-icons
{
	display: block;
}
.team-box .team-content
{
	padding-top: 23px;
	padding-bottom: 26px;
	background: #FFF;
	position: relative;
	z-index: 1;
}
.single.team-box .team-content
{
	background: #F5F5F5;
}
.team-box ul.social-icons li
{
	border: none;
	margin-left: 1px;
	background: #FFF;
}
.team-box ul.social-icons li a
{
	padding: 17px 18px;
	width: 14px;
	height: 16px;
}
.team-box ul.social-icons li:first-child
{
	margin-left: 0;
}
.team-box ul.social-icons li:hover
{
	background: #F4BC16;
}
.team-box ul.social-icons li:hover a
{
	color: #FFF;
}
/* --- projects --- */
.projects-list
{
	width: 100%;
	transition: all 0.4s ease 0s;
	-webkit-transition: all 0.4s ease 0s;
	-moz-transition: all 0.4s ease 0s;
}
.projects-list li
{
	position: relative;
	float: left;
	width: 25%;
	overflow: hidden;
}
.projects-list.isotope li
{
	width: 270px;
	height: 180px;
	margin-top: 30px;
}
.projects-list li img
{
	width: 100%;
}
.projects-list a>.re-preloader
{
	height: 250px;
}
.projects-list li .view
{
	position: absolute;
	top: 0;
	background: rgba(38, 38, 40, 0.5);
	width: 100%;
	height: 100%;
	opacity: 0;
	transition: all 0.3s ease 0s;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
}
.projects-list li:hover .view
{
	opacity: 1;
}
.projects-list li .view .vertical-align-table
{
	width: 100%;
	height: 100%;
}
.projects-list li .view p.description
{
	color: #FFF;
	margin-top: -20px;
	margin-bottom: 55px;
	font-size: 14px;
	line-height: 24px;
	transition: all 0.3s ease 0s;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
}
.projects-list li:hover .view  p.description
{
	margin-bottom: 35px;
}
/* --- features --- */
.features-list li
{
	float: left;
	width: 100%;
	margin-top: 50px;
}
.features-list.big li
{
	text-align: center;
}
.features-list li:first-child
{
	margin-top: 0;
}
.features-list li:before
{
	float: left;
	font-size: 48px;
	margin-right: 30px;
	color: #F4BC16;
	border: 1px solid #E2E6E7;
	padding: 19px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}
.features-list.big li:before
{
	clear: both;
	float: none;
	display: block;
	width: 60px;
	font-size: 60px;
	margin-left: auto;
	margin-right: auto;
	padding: 39px;
}
.features-list li h4
{
	padding-bottom: 5px;
}
.features-list li p
{
	font-family: 'Raleway';
	font-size: 14px;
	line-height: 24px;
	padding: 0;
	margin-top: 19px;
	margin-left: 118px;
}
.features-list.big li h4
{
	margin-top: 35px;
}
.features-list.big li h4:after
{
	margin-top: 20px;
	margin-left: auto;
	margin-right: auto;
}
.features-list.big li p
{
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}
.column-1-4 .features-list.big li p
{
	width: 100%;
}
.features-list.big .ornament
{
	position: relative;
	width: 1px;
	height: 20px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 13px;
	background: #E2E6E7;
}
.features-list.big .ornament:after
{
	position: absolute;
	content: "";
	border: 1px solid #E2E6E7;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	width: 11px;
	height: 11px;
	left: -6px;
	bottom: -13px;
}
.page-404 .features-list.big li p
{
	font-size: 16px;
	line-height: 26px;
	margin-top: 34px;
}
/* --- timeline --- */
.timeline-item .timeline-left
{
	width: 190px;
	min-width: 190px;
}
.timeline-item .label-container
{
	position: relative;
}
.timeline-item .label-container .timeline-circle
{
	position: absolute;
	display: block;
	width: 19px;
	height: 19px;
	top: 4px;
	right: -11px;
	border: 1px solid #E2E6E7;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	background: #FFF;
}
.timeline-item .label-container .timeline-circle::after
{
	content: "";
	display: block;
	width: 7px;
	height: 7px;
	margin-left: 6px;
	margin-top: 6px;
	background: #F4BC16;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}
.timeline-item label
{
	float: right;
	max-width: 100px;
	overflow: hidden;
	font-family: 'Raleway';
	font-weight: 500;
	color: #FFF;
	font-size: 13px;
	line-height: 20px;
	background: #F4BC16;
	padding: 4px 15px 6px 20px;
	white-space: nowrap;
}
.timeline-item .label-triangle
{
	float: right;
	width: 0;
	height: 0;
	margin-right: 40px;
	border-style: solid;
	border-width: 15px 0 15px 15px;
	border-color: transparent transparent transparent #F4BC16;
}
.timeline-content
{
	padding: 22px 0 26px 40px;
	border-left: 1px solid #E2E6E7;
}
.timeline-content span.timeline-title
{
	margin-right: 10px;
}
.timeline-content span.timeline-subtitle
{
	font-family: 'Raleway';
	font-weight: 500;
	font-size: 11px;
	color: #999;
	line-height: 16px;
	letter-spacing: 1px;
	margin-top: 9px;
}
/* --- testimonials --- */
.testimonials-container
{
	position: relative;
}
.testimonials-container .caroufredsel_wrapper_testimonials
{
	width: 1050px !important;
	left: 60px !important;
}
.testimonials-container.type-small .caroufredsel_wrapper_testimonials
{
	width: 100% !important;
	left: 0 !important;
}
.testimonials-list li
{
	float: left;
	width: 1050px;
	text-align: center;
}
.testimonials-list li::before
{
	display: block;
	width: 48px;
	font-size: 48px;
	margin-left: auto;
	margin-right: auto;
	color: #FFF;
	border: 1px solid #E2E6E7;
	padding: 19px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}
.testimonials-list .ornament
{
	position: relative;
	width: 1px;
	height: 20px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 13px;
	background: #E2E6E7;
}
.testimonials-list .ornament::after
{
	position: absolute;
	content: "";
	border: 1px solid #E2E6E7;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	width: 11px;
	height: 11px;
	left: -6px;
	bottom: -13px;
}
.testimonials-list p,
.new-helmet h4 {
	font-family: 'Bariol';
	font-weight: 300;
	font-size: 21px;
	color: #606060;
	line-height: 36px;
	padding: 29px 50px 0;
}

.new-helmet p{
    font-family: 'Bariol';
    font-weight: 300;
    font-size: 18px;
    color: #747474;
    line-height: 36px;
    padding: 29px 50px 0;
}


.testimonials-list p::after
{
	display: block;
	content: "";
	/*background: #F4BC16;*/
	width: 40px;
	height: 2px;
	margin-top: 30px;
	margin-bottom: 34px;
	margin-left: auto;
	margin-right: auto;
}
.testimonials-list  .author
{
	font-family: 'Raleway';
	font-weight: 500;
	font-size: 13px;
	color: #FFF;
	line-height: 20px;
}
.testimonials-list  .author-details
{
	margin-top: 7px;
	font-family: 'Raleway';
	font-weight: 500;
	font-size: 11px;
	color: #FFF;
	line-height: 16px;
	letter-spacing: 1px;
}
.testimonials-container .re-carousel-pagination
{
	position: absolute;
	right: 0;
	top: -52px;
}
.testimonials-container .re-carousel-pagination a
{
	margin-top: 0;
}
.type-small .testimonials-list p
{
	font-size: 16px;
	line-height: 26px;
	color: #444;
	border: 1px solid #E2E6E7;
	padding: 33px 40px 37px;
}
.type-small .testimonials-list p::after
{
	content: none;
}
.type-small .testimonials-list .ornament
{
	float: left;
	margin-left: 44px;
	margin-right: 43px;
	height: 101px;
}
.type-small .testimonials-list .ornament::after
{
	background: #FFF;
	bottom: 0;
	top: -7px;
}
.type-small .testimonials-list .ornament::before
{
	position: relative;
	display: block;
	color: #F4BC16;
	background: #FFF;
	width: 48px;
	font-size: 48px;
	top: 26px;
	left: -44px;
	border: 1px solid #E2E6E7;
	padding: 19px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}
body .type-small .testimonials-list .ornament.empty-circle
{
	height: 79px;
	margin-right: 6px;
}
body .type-small .testimonials-list .ornament.empty-circle::before
{
	content: "";
	width: 11px;
	height: 11px;
	top: 66px;
	left: -6px;
	padding: 0;
}
.type-small .testimonials-list .author-details-box
{
	float: left;
	margin-top: 50px;
	margin-left: 30px;
}
.type-small .testimonials-list .author
{
	color: #444;
}
.type-small .testimonials-list .author-details
{
	color: #999;
}
.column-1-2 .type-small .testimonials-list li,
.column-1-3 .type-small .testimonials-list li
{
	width: 570px;
	text-align: left;
	margin-right: 30px;
}
.column-1-3 .type-small .testimonials-list li
{
	width: 370px;
}
/* --- our clients --- */
.our-clients-list-container
{
	overflow: hidden;
	text-align: center;
}
.our-clients-list li
{
	float: left;
	width: 170px;
	height: 120px;
	margin-right: 30px;
}
.our-clients-list li img
{
	width: 100%;
	height: auto;
}
.our-clients-list.type-list li
{
	position: relative;
	margin-right: -1px;
	border: 1px solid #E2E6E7;
	width: 33%;
	height: 150px;
	text-align: center;
}
.our-clients-list.type-list li:nth-child(n+4)
{
	margin-top: -1px;
}
.our-clients-list.type-list .our-clients-item-container
{
	display: inline-block;
}
.our-clients-list.type-list li .vertical-align-cell
{
	text-align: center;
	height: 150px;
}
/* --- contact details --- */
.contact-details-list li:before
{
	display: block;
	float: left;
	width: 48px;
	height: 48px;
	font-size: 48px;
	color: #F4BC16;
	background: #FFF;
	border: 1px solid #FFF;
	padding: 19px;
	margin-right: 30px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}
.contact-details-list li p
{
	font-family: 'Raleway';
	font-size: 16px;
	line-height: 26px;
	color: #FFF;
}
.contact-details-list li p a
{
	color: #FFF;
}
/* --- slider control --- */
.slider-control
{
	position: absolute;
	width: 26px;
	height: 26px;
	padding: 16px;
	top: 43%;
	font-size: 24px;
	z-index: 1;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	transition: all 0.3s ease 0s;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
}
.slider-control.right
{
	right: 0;
	-moz-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	transform: scaleX(-1);
	-ms-filter: fliph;
	filter: fliph;
}
.slider-control:hover
{
     /*	background: #F4BC16;
	border-color: #F4BC16; */
}
/* --- backgrounds --- */
.background-1
{
	background-image: url('../images/samples/750x500/image_07.jpg');
	background-size: cover;
	background-position: center;
}
/* --- parallax --- */
.parallax-1
{
	background-image: url('../images/samples/1920x1200/image_01.jpg');
}
.parallax-2
{
	background-image: url('../images/samples/1920x1200/image_02.jpg');
}
.parallax,
.parallax h2,
.parallax h4,
.parallax p,
.parallax .features-list li::before
{
	color: #FFF;
}
.parallax.cover
{
	background-size: cover;
}
/* --- counters --- */
span.number,
span.odometer.number
{
	margin-top: 10px;
	font-family: 'Raleway';
	font-weight: 100;
	font-size: 80px;
	color: #FFF;
	visibility: visible;
}
span.number:after,
span.odometer.number:after
{
	display: block;
	content: "";
	background: #F4BC16;
	width: 40px;
	height: 2px;
	margin: 20px auto 0;
}
/* --- blog --- */
.blog>li
{
	clear: both;
	float: left;
	margin-top: 70px;
}
.blog.two-columns>li,
.blog.three-columns>li
{
	clear: none;
	margin-top: 0;
}
.blog.two-columns
{
	margin-top: 70px;
}
.blog>li:first-child,
.blog.small>li:first-child
{
	margin-top: 0;
}
.blog .post-image,
.blog .post-content,
.blog .post-details,
.blog .post-content-details li
{
	float: left;
}
.blog .post-details
{
	width: 90px;
	margin-right: 30px;
	font-family: 'Raleway';
	font-weight: 500;
	font-size: 13px;
	color: #999;
	line-height: 20px;
}
.blog .post-details a
{
	color: #999;
}
.blog .post-content
{
	width: 750px;
}
.blog .post-content .re-preloader
{
	height: 300px;
}
.blog .post-content .row
{
	width: auto;
}
.blog.small .post-content
{
	width: 160px;
}
.blog .post-details li
{
	border-top: 1px solid #E2E6E7;
	border-right: 1px solid #E2E6E7;
	text-align: center;
	padding: 10px 0;
}
.blog .post-details li:first-child
{
	border-top: none;
}
.blog .post-details .date h2,
.blog .post-details li:before
{
	color: #F4BC16;
}
.blog .post-details li.date:before
{
	display: none;
}
.blog .post-details li:before
{
	display: block;
	margin-bottom: 2px;
	font-size: 14px;
}
.blog .post-content-details
{
	margin-bottom: 16px;
}
.blog .post-content-details li
{
	font-family: 'Raleway';
	font-weight: 500;
	font-size: 13px;
	color: #999;
	line-height: 20px;
	margin-left: 10px;
}
.blog .post-content-details li:first-child
{
	margin-left: 0;
}
.blog .post-content-details li a
{
	color: #444;
}
.blog p.description.t1
{
	margin-top: 34px;
}
.blog.horizontal-carousel p.description.t1
{
	margin-top: 24px;
}
.blog .post-image
{
	width: 100%;
	margin-bottom: 25px;
}
.blog.small>li
{
	margin-top: 30px;
}
.blog.small .post-content a
{
	display: block;
	font-family: 'Bariol';
	font-size: 14px;
	color: #444444;
	line-height: 24px;
}
.blog.small .post-image
{
	width: auto;
	margin-bottom: 0;
	margin-right: 20px;
}
.blog.small li .post-details
{
	width: auto;
	clear: both;
	margin-top: 13px;
	margin-right: 0;
}
.blog.small li .post-details li
{
	text-align: left;
	padding: 0;
	border: none;
}
.column-1-2 .blog .post-content,
.blog .column-1-2 .post-content
{
	width: 450px;
}
.blog.three-columns .post-details
{
	width: 100%;
	margin-right: 0;
	border-bottom: 1px solid #E2E6E7;
	margin-bottom: 20px;
}
.blog.three-columns .post-details li
{
	float: left;
	border-top: none;
	padding: 10px 20px;
}
.blog.three-columns .post-details li:first-child
{
	padding-left: 0;
}
.blog.three-columns .post-details li:last-child
{
	border-right: none;
	padding-right: 0;
}
.blog.three-columns .post-details li::before,
.blog.three-columns .post-details li.date:before
{
	display: inline;
	margin-bottom: 0;
	margin-right: 10px;
}
.blog.three-columns .post-details li.date h2
{
	display: inline;
	margin: 0 2px;
	color: #999;
	font-size: 13px;
	font-weight: 500;
	line-height: 1;
}
.blog.three-columns .post-details li.date h2::after
{
	content: ",";
}
/* --- post carousel --- */
.caroufredsel-wrapper
{
	width: 100% !important;
	margin: 0 !important;
	overflow: hidden;
}
.blog.horizontal-carousel .column
{
	margin-left: 0;
	margin-right: 30px;
}
.horizontal-carousel
{
	display: none;
	transition: opacity 0.4s ease-in-out 0s;
}
.carousel-container
{
	position: relative;
}
.re-carousel-pagination
{
	display: block;
	text-align: center;
}
.re-carousel-pagination a
{
	display: inline-block;
	width: 1px;
	height: 1px;
	background: #E2E6E7;
	border: 5px solid #E2E6E7;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	margin-left: 10px;
	margin-top: 50px;
}
.re-carousel-pagination a:first-child
{
	margin-left: 0;
}
.re-carousel-pagination a.selected,
.re-carousel-pagination a:hover
{
	border-color: #F4BC16;
	background: #F4BC16;
}
.re-carousel-pagination span
{
	display: none;
}
/* --- single post --- */
.post.single p
{
	font-family: "Raleway";
	font-size: 14px;
	line-height: 24px;
}
.post.single blockquote, .post.single p, .post.single h3, .post.single h4
{
	margin-top: 24px;
}
/* --- taxonomies --- */
.taxonomies li
{
	float: left;
	margin-right: 7px;
	margin-bottom: 7px;
}
.taxonomies a
{
	display: block;
	border: 1px solid #E2E6E7;
	color: #999;
	line-height: 16px;
	letter-spacing: 1px;
	font-family: 'Raleway';
	font-size: 11px;
	font-weight: 500;
	padding: 10px 12px;
	text-align: left;
}
.taxonomies a:hover
{
	color: #FFF;
	background: #F4BC16;
	border-color: #F4BC16;
}
.taxonomies.full-width li
{
	width: 100%;
	margin-right: 0;
}
.taxonomies a span
{
	float: right;
}
/* --- buttons --- */
.buttons li
{
	margin-top: 10px;
}
.buttons li:first-child
{
	margin-top: 0;
}
.buttons li a
{
	display: block;
	background: #F5F5F5;
	padding: 18px 18px 18px 19px;
	font-family: 'Raleway';
	line-height: 24px;
	color: #444;
}
.buttons li:before
{
	color: #F4BC16;
	float: left;
	font-size: 24px;
	margin-right: 19px;
	margin-top: 17px;
	margin-left: 20px;
}
.buttons li.selected a,
.buttons li:hover a
{
	background: #F4BC16;
	color: #FFF;
}
.buttons li.selected:before,
.buttons li:hover:before
{
	color: #FFF;
}
/* --- announcement --- */
.announcement
{
	width: 1170px;
	margin-left: auto;
	margin-right: auto;
	padding: 30px 0;
}
.announcement .vertical-align-cell:before
{
	float: left;
	font-size: 48px;
	margin-right: 30px;
	color: #F4BC16;
	border: 1px solid #E2E6E7;
	padding: 19px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}
.announcement .vertical-align
{
	height: 88px;
}
.announcement  h3
{
	margin-left: 118px;
}
.announcement p.description
{
	margin-top: 14px;
	margin-left: 118px;
	/*white-space: nowrap;*/
}
/* --- call to action --- */
.call-to-action
{
	border: 1px solid #E2E6E7;
	padding: 30px 18px 47px;
	text-align: center;
}
.call-to-action:before
{
	display: block;
	width: 48px;
	height: 48px;
	font-size: 48px;
	margin-right: 30px;
	color: #F4BC16;
	border: 1px solid #E2E6E7;
	padding: 19px;
	margin-left: auto;
	margin-right: auto;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}
.call-to-action h4
{
	margin-top: 22px;
}
.call-to-action p.description
{
	margin-top: 17px;
	margin-bottom: 43px;
}
.call-to-action .more
{
	margin-top: 28px;
}
/* --- progress bars --- */
.progress-bar .single-bar
{
	position: relative;
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	box-shadow: none;
	background: #E2E6E7;
}
.progress-bar .single-bar .bar-label
{
	position: relative;
	display: block;
	font-family: "Raleway";
	font-size: 14px;
	color: #444;
	text-shadow: none;
	height: 10px;
	bottom: -29px;
	padding: 0;
	z-index: 1;
}
.progress-bar .single-bar,
.progress-bar .single-bar:last-child
{
	margin-bottom: 64px;
}
.progress-bar .single-bar .bar
{
	position: absolute;
	display: block;
	visibility: visible;
	width: 0;
	left: 0;
	top: 0;
	height: 100%;
	background: #F4BC16;
	-moz-transition: width 1s ease 0s;
	-webkit-transition: width 1s ease 0s;
	transition: width 1s ease 0s;
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
}
.bar-label-units
{
	float: right;
	color: #999;
}
/* --- comment form, contact form --- */
.comment-form input,
.comment-form textarea,
.contact-form input,
.contact-form textarea
{
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	width: 100%;
}
.comment-form input,
.contact-form input
{
	margin-top: 20px;
}
.comment-form input:first-child,
.contact-form input:first-child
{
	margin-top: 0;
}
.comment-form textarea,
.contact-form textarea
{
	height: 190px;
	resize: none;
}
.comment-form [type='submit'],
.contact-form [type='submit']
{
	width: auto;
	font-size: 12px;
	color: #25282a;
	margin-top: 0;
	outline: none;
}
#cancel-comment
{
	display: none;
	font-size: 12px;
	margin-right: 20px;
	color: #F4BC16;
}
.comment-form p.description.t1,
.contact-form p.description.t1
{
	margin-top: 14px;
}
.comment-form .hint,
.contact-form .hint
{
	color: #999;
}
::-webkit-input-placeholder
{
	color: #999;
}
:-moz-placeholder
{
	color: #999;
	opacity:  1;
}
::-moz-placeholder
{
	color: #999;
	opacity:  1;
}
:-ms-input-placeholder
{
	color: #999;
}
/* --- tooltip --- */
.ui-tooltip-error .ui-tooltip-content,
.ui-tooltip-success .ui-tooltip-content
{
	font-size: 13px;
	font-family: arial;
	color: #FFF;
}
.ui-tooltip-error .ui-tooltip-content,
.ui-tooltip-success .ui-tooltip-content
{
	border:none;
}
.ui-tooltip-content
{
	padding: 10px 20px 10px 20px;
}
.ui-tooltip-error .ui-tooltip-content
{
	background: #E9431C;
}
.ui-tooltip-success .ui-tooltip-content
{
	background: #61911B;
}
/* --- comments list --- */
.comment
{
	margin-top: 30px;
}
#comments-list>.comment:first-child
{
	margin-top: 40px;
}
.comment-author-avatar
{
	float: left;
	width: 90px;
	height: 90px;
}
.avatar-1
{
	background-image: url("../images/samples/90x90/users/image_01.png");
}
.avatar-2
{
	background-image: url("../images/samples/90x90/users/image_02.png");
}
.avatar-3
{
	background-image: url("../images/samples/90x90/users/image_03.png");
}
.avatar-4
{
	background-image: url("../images/samples/90x90/users/image_04.png");
}
.avatar-5
{
	background-image: url("../images/samples/90x90/users/image_05.png");
}
.avatar-6
{
	background-image: url("../images/samples/90x90/users/image_06.png");
}
.avatar-7
{
	background-image: url("../images/samples/90x90/users/image_07.png");
}
.avatar-8
{
	background-image: url("../images/samples/90x90/users/image_08.png");
}
.posted-by abbr
{
	font-family: 'Raleway';
	color: #999;
	font-size: 13px;
	margin-top: 7px;
	font-weight: 500;
	line-height: 20px;
	text-decoration: none;
}
.posted-by .in-reply
{
	margin-left: 5px;
	color: #ABABAB;
}
#comments-list .comment-details
{
	float: left;
	width: 750px;
	margin-left: 30px;
}
#comments-list .comment-details p
{
	font-family: 'Raleway';
	font-size: 14px;
	line-height: 24px;
	border-bottom: 1px solid #E2E6E7;
	padding: 24px 0;
}
#comments-list .children
{
	clear: both;
	float: left;
	margin-left: 120px;
}
#comments-list .children .children
{
	margin-left: 0;
}
#comments-list .children .comment-details
{
	width: 630px;
}
#comments-list .comment-details .more
{
	float: right;
	color: #999;
	margin-top: -22px;
	font-weight: 500;
	font-size: 11px;
	line-height: 16px;
}
#comments-list .comment-details .more:hover
{
	color: #FFF;
}
/* --- pagination --- */
.pagination
{
	clear: both;
	float: right;
}
.pagination li
{
	font-family: 'Raleway';
	float: left;
	margin-left: 7px;
}
.pagination li:first-child
{
	margin-left: 0;
}
.pagination li.left a:before,
.pagination li.right a:before
{
	color: #999;
}
.pagination li a:hover:before,
.pagination li a.selected:before
{
	color: #FFF;
}
.pagination li.left a,
.pagination li.right a
{
	padding: 9px 0;
}
.pagination li.right
{
	-moz-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	transform: scaleX(-1);
	-ms-filter: fliph;
	filter: fliph;
}
.pagination li a
{
	display: block;
	color: #999;
	font-size: 16px;
	width: 35px;
	padding: 9px 0 10px;
	border: 1px solid #E2E6E7;
	text-align: center;
}
.pagination li a:hover,
.pagination li.selected a
{
	color: #FFF;
	background-color: #F4BC16;
	border-color: #F4BC16;
}
/* --- list bullet --- */
.list li
{
	padding-top: 3px;
	padding-bottom: 3px;
	font-family: 'Raleway';
	font-size: 14px;
	line-height: 24px;
	padding-left: 30px;
}
.list li a
{
	color: #F4BC16;
	font-weight: 600;
}
li.template-bullet:before
{
	color: #F4BC16;
	padding-right: 15px;
	margin-left: -30px;
	vertical-align: text-top;
}
/* --- tabs navigation --- */
.tabs-navigation
{
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	box-sizing: content-box;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
	background: none;
	border: none;
	text-align: center;
}
.tabs.align-left .tabs-navigation
{
	text-align: left;
}
.tabs.align-left .ui-tabs-panel
{
	text-align: left;
}
.tabs-navigation li
{
	position: relative;
	display: inline-block;
	padding: 0;
	border: none;
	list-style: none;
	text-align: center;
	margin-left: 26px;
}
.tabs-navigation li:first-child
{
	margin-left: 0;
}
.tabs-navigation.small.gray li a
{
	background: #F5F5F5;
}
.tabs-navigation.small li
{
	margin-right: 6px;
	margin-bottom: 10px;
	margin-left: 0
}
.tabs-navigation.small li a
{
	min-width: 0;
	padding: 15px 20px;
}
.tabs-navigation a:before
{
	display: block;
	margin-left: auto;
	margin-right: auto;
	font-size: 48px;
	color: #F4BC16;
	padding: 20px 20px 12px;
}
.tabs-navigation li a
{
	display: block;
	min-width: 180px;
	text-align: center;
	font-size: 14px;
	color: #444;
	background: #FFF;
	padding: 10px 0 23px;
	border: none;
	font-family: 'Raleway';
	line-height: 24px;
	outline: none;
	text-decoration: none;
}
.tabs-navigation li:last-child a
{
	border-right: none;
}
.tabs-navigation li a:hover,
.tabs-navigation li a.selected,
.tabs-navigation li.ui-tabs-active a,
.tabs-navigation.small.gray li a:hover,
.tabs-navigation.small.gray li a.selected,
.tabs-navigation.small.gray li.ui-tabs-active a
{
	background: #F4BC16;
	color: #FFF;
}
.tabs-navigation li a:hover:before,
.tabs-navigation li a.selected:before,
.tabs-navigation li.ui-tabs-active a:before
{
	color: #FFF;
}
.tabs-navigation li.ui-tabs-active span
{
	display: inline;
	position: absolute;
	border-style: solid;
	border-width: 9px 9px 0;
	border-color: #F4BC16 transparent;
	bottom: -9px;
	margin-left: -9px;
}
.tabs .ui-tabs-panel
{
	text-align: center;
	padding: 42px 0 0;
}
.tabs.small .ui-tabs-panel
{
	padding: 0;
}
.tabs .ui-tabs-panel p
{
	font-family: 'Raleway';
	font-size: 14px;
	line-height: 24px;
	padding: 34px 0 0;
}
.tabs.small .ui-tabs-panel p
{
	padding: 24px 0 0;
}
.tabs .ui-tabs-panel h4.box-header:after
{
	margin-left: auto;
	margin-right: auto;
}
/* --- accordion --- */
.accordion .ui-accordion-content
{
	padding: 30px 0 20px;
}
.accordion .ui-accordion-header
{
	padding: 16px 15px;
	cursor: pointer;
	border-bottom: 1px solid #E2E6E7;
	font-family: 'Raleway';
	line-height: 24px;
	transition: padding-left 300ms, padding-right 300ms;
	-webkit-transition: padding-left 300ms, padding-right 300ms;
	-moz-transition: padding-left 300ms, padding-right 300ms;
	transition-timing-function: ease;
	-webkittransition-timing-function: ease;
	-mozkittransition-timing-function: ease;
}
.accordion .ui-accordion-header h5
{
	font-weight: 400;
	letter-spacing: normal;
	margin-top: 2px;
	margin-bottom: 1px;
	margin-left: 39px;
}
.accordion .ui-accordion-header.ui-state-active
{
	background-color: #F4BC16;
	border-bottom-color: #F4BC16;
}
.accordion .ui-accordion-header.ui-state-active h5
{
	color: #FFF;
}
.accordion .ui-accordion-header.ui-state-active .ui-accordion-header-icon
{
	color: #FFF;
}
.accordion  .ui-accordion-header .ui-accordion-header-icon
{
	float: left;
	margin-right: 15px;
	font-size: 24px;
	color: #F4BC16;
}
.accordion  .ui-accordion-header:hover,
.global-title
{
	padding-left: 25px;
	padding-right: 5px;
}
.accordion  .ui-accordion-header.ui-state-active:hover
{
	padding: 16px 15px;
}
.accordion p.description
{
	margin: 0;
}
/* --- preloader --- */
.re-preloader
{
	display: block;
	height: auto;
	min-height: 20px;
	background: url('../images/preloader.gif') no-repeat center center;
}
.blog .post-content>a>img,
.post.single .post-image img,
.our-clients-list,
.services-list a>img,
.team-box img,
.projects-list a>img,
.re-preload>img,
.re-preload>.image-wrapper>img
{
	display: none;
}
.our-clients-list.type-list
{
	display: block;
}
.projects-list.isotope li>a,
.projects-list.isotope a>img
{
	display: block;
	width: 270px;
	height: 180px;
}
.re-preload .re-preloader
{
	height: 200px;
}
/* --- contact details --- */
.header-top-bar .contact-details
{
	float: left;
}
.contact-details li
{
	float: left;
	border-right: 1px solid #E2E6E7;
	font-family: 'Raleway';
	font-weight: 500;
	font-size: 13px;
	color: #999999;
	line-height: 20px;
	padding: 15px 20px;
}
.contact-details li a
{
	color: #999;
}
.contact-details li:first-child
{
	border-left: 1px solid #E2E6E7;
}
.contact-details li::before
{
	color: #AAA;
	font-size: 14px;
	margin-right: 10px;
}
/* --- social icons --- */
.social-icons li
{
	float: right;
	border-right: 1px solid #E2E6E7;
}
.social-icons li:first-child
{
	border-left: 1px solid #E2E6E7;
}
.social-icons a
{
	display: block;
	color: #AAA;
}
.social-icons a:hover
{
	color: #444;
}
.social-icons a:before
{
	font-size: 14px;
}
.header-top-bar .social-icons
{
	float: right;
}
.social-icons li a
{
	padding: 6px;
}
.social-icons.yellow li
{
	background: #F4BC16;
	border: 2px solid #F4BC16;
	margin-left: 1px;
}
.social-icons.yellow li:hover
{
	background: transparent;
}
.social-icons.yellow li a
{
	padding: 15px 16px;
	height: 16px
}
.social-icons.yellow li a:before
{
	color: #FFF;
}
.social-icons.yellow li a:hover:before
{
	color: #000;
}
.social-icons.yellow li:first-child
{
	margin-left: 0;
}
/* --- search --- */
.header-top-bar .search-container
{
	float: right;
	position: relative;
}
.header-top-bar .template-search
{
	display: block;
	font-size: 14px;
	padding: 17px;
	border-right: 1px solid #E2E6E7;
	color: #AAA;
}
.template-search:hover
{
	color: #444;
}
.search
{
	position: absolute;
	display: none;
	width: 179px;
	right: 0;
	padding: 12px 10px;
	margin-top: 5px;
	background: #F5F5F5;
	z-index: 99;
}
.search .search-input
{
	float: left;
	width: 91px;
	color: #444;
	font-size: 14px;
	background: #FFF;
	border: 1px solid #E2E6E7;
	margin: 0;
	padding: 12px 14px 12px 12px;
}
.search .search-input.hint
{
	color: #858d94;
}
.search .search-input::-webkit-input-placeholder
{
	color: #858d94;
}
.search .search-input:-moz-placeholder
{
	color: #858d94;
	opacity:  1;
}
.search .search-input::-moz-placeholder
{
	color: #858d94;
	opacity:  1;
}
.search .search-input:-ms-input-placeholder
{
	color: #858d94;
}
.search .search-submit-container
{
	position: relative;
	float: right;
}
.header-top-bar .search .template-search
{
	padding: 18px 18px 17px 18px;
	border: none;
}
.search .search-submit-container:hover .template-search
{
	color: #444;
}
.search .search-submit
{
	position: absolute;
	width: 50px;
	height: 50px;
	background: none;
	padding: 0;
	top: 0;
	left: 0;
	cursor: pointer;
}
/* --- ui slider --- */
.ui-slider
{
	position: relative;
	text-align: left;
}
.ui-slider .ui-slider-handle
{
	position: absolute;
	z-index: 2;
	cursor: default;
	-ms-touch-action: none;
	touch-action: none;
}
.ui-slider .ui-slider-range
{
	position: absolute;
	z-index: 1;
	font-size: .7em;
	display: block;
	border: 0;
	background-position: 0 0;
}
/* support: IE8 - See #6727 */
.ui-slider.ui-state-disabled .ui-slider-handle,
.ui-slider.ui-state-disabled .ui-slider-range
{
	filter: inherit;
}
.ui-slider-horizontal .ui-slider-handle
{
	margin-left: -.6em;
}
.ui-slider-horizontal .ui-slider-range
{
	top: 0;
	height: 100%;
}
.ui-slider-horizontal .ui-slider-range-min
{
	left: 0;
}
.ui-slider-horizontal .ui-slider-range-max
{
	right: 0;
}
/* --- ui dropdown --- */
/* right-aligned */
.ui-menu .ui-menu-icon {
	left: auto;
	right: 0;
}
.ui-selectmenu-menu {
	padding: 0;
	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	display: none;
}
.ui-selectmenu-menu .ui-menu {
	overflow: auto;
	/* Support: IE7 */
	overflow-x: hidden;
	padding-bottom: 1px;
}
.ui-selectmenu-menu .ui-menu .ui-selectmenu-optgroup {
	font-size: 1em;
	font-weight: bold;
	line-height: 1.5;
	padding: 2px 0.4em;
	margin: 0.5em 0 0 0;
	height: auto;
	border: 0;
}
.ui-selectmenu-open {
	display: block;
}
.ui-selectmenu-button {
	display: inline-block;
	overflow: hidden;
	position: relative;
	text-decoration: none;
	cursor: pointer;
}
.ui-selectmenu-button span.ui-icon
{
	right: 0;
	position: absolute;
}
.ui-selectmenu-button span.ui-selectmenu-text
{
	text-align: left;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
/* --- cost calculator --- */
.cost-calculator-box
{
	padding: 30px;
	background: #F5F5F5;
	margin-top: 1px;
}
.column-2-3 .cost-calculator-box .column-1-2
{
	width: 340px;
}
.cost-calculator-box:before
{
	float: left;
	font-size: 48px;
	margin-right: 30px;
	color: #F4BC16;
	border: 1px solid #E2E6E7;
	padding: 19px;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}
.cost-calculator-box:first-child
{
	margin-top: 0;
}
.cost-calculator-box label
{
	float: left;
	font-family: 'Raleway';
	font-size: 14px;
	line-height: 24px;
	border-left: 4px solid #F4BC16;
	padding: 14px 0 12px 15px;
}
.cost-calculator-box .cost-slider-container
{
	float: right;
	margin-left: 30px;
}
.cost-calculator-box input
{
	float: right;
	width: 40px;
	margin-left: 30px;
	margin-top: 0;
	box-sizing: content-box;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
}
.cost-calculator-box input.big
{
	width: 370px;
}
.cost-calculator-box.cost-calculator-contact input
{
	width: 100%;
	margin-top: 20px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}
.cost-calculator-box.cost-calculator-contact input:first-child
{
	margin-top: 0;
}
.cost-calculator-box.cost-calculator-contact input[type='submit']
{
	width: auto;
	margin-top: 0;
}
.cost-calculator-sum
{
	text-align: right;
	margin-top: 10px;
}
.contact-form .cost-calculator-box p.description.t1
{
	margin-top: 14px;
	padding: 0;
}
.cost-calculator-price
{
	font-family: 'Raleway';
	font-weight: 300;
	font-size: 40px;
	color: #F4BC16;
}
.ui-slider
{
	float: right;
	width: 300px;
	margin-top: 20px;
	background-color: #E2E6E7;
	border-radius: 5px;
}
.ui-slider-horizontal
{
	height: 10px;
}
.ui-slider .ui-slider-handle
{
	cursor: pointer;
	width: 28px;
	height: 28px;
	top: -10px;
	border: 1px solid #E2E6E7;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	background: #FFF;
}
.ui-slider-handle:after
{
	content: "";
	display: block;
	width: 10px;
	height: 10px;
	margin-left: 9px;
	margin-top: 9px;
	background: #F4BC16;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}
.ui-slider-handle .cost-slider-tooltip
{
	position: absolute;
	min-width: 50px;
	left: -11px;
	top: 35px;
}
.ui-slider-handle .cost-slider-tooltip .value
{
	width: 100%;
	font-family: 'Raleway';
	color: #FFF;
	background: #F4BC16;
	padding: 12px 0;
	text-align: center;
}
.ui-slider-handle .cost-slider-tooltip .arrow
{
	width: 100%;
	height: 8px;
	text-align: center;
}
.ui-slider-handle .cost-slider-tooltip .arrow::before
{
	content: "";
	border-color: #F4BC16 transparent;
	border-style: solid;
	border-width: 0 8px 8px;
	width: 1px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	position: relative;
}
.ui-slider-range-min
{
	background: #F4BC16;
	border-radius: 5px;
}
.ui-selectmenu-button
{
	float: right;
	font-family: 'Raleway';
	border: 1px solid #E2E6E7;
	margin: 0;
	background: #FFF;
}
.ui-selectmenu-button span.ui-selectmenu-text
{
	padding: 12px 40px 12px 15px;
	line-height: 24px;
        font-family: 'Bariol';
	font-size: 16px;
	color: #444;
}
.ui-selectmenu-button .ui-icon
{
	color: #444;
	border-left: 1px solid #E2E6E7;
	padding: 16px 12px 17px;
}
.ui-selectmenu-button.ui-corner-top .ui-icon,
.ui-selectmenu-button:hover .ui-icon
{
	color: #F4BC16;
}
.ui-selectmenu-menu
{
	z-index: 2;
}
.ui-selectmenu-menu .ui-menu
{
	border: 1px solid #E2E6E7;
	border-top: none;
	max-height: 204px;
}
.ui-selectmenu-menu .ui-menu li
{
	font-family: 'Raleway';
	cursor: pointer;
	background: #FFF;
	color: #444;
	border-top: 1px solid #E2E6E7;
	padding: 13px 15px;
}
.ui-selectmenu-menu .ui-menu li:first-child
{
	border-top: 0;
}
.ui-selectmenu-menu .ui-menu li.ui-state-focus
{
	color: #F4BC16;
}
/* --- buttons --- */
.more,
.more[type="submit"]
{
	color: #FFF;
	font-family: 'Bariol';
	font-size: 1rem;
	font-weight: 700;
	background: #F4BC16;
	padding: 6px 27px 7px;
	text-align: center;
	cursor: pointer;
	line-height: normal;
	letter-spacing: 1px;
	border-radius: 999px;
	-moz-border-radius: 999px;
	-webkit-border-radius: 999px;
	transition: all 0.3s ease 0s;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
	border: 2px solid #F4BC16;
}
.more:hover
{
	color: #330099;
	border: 1px solid #330099;
	background: transparent;
	opacity: 1;
}
.more.simple
{
	background: transparent;
	color: #FFF;
	border: 1px solid #E2E6E7;
	padding: 17px 24px 16px;
	transition: all 0.3s ease 0s;
	-webkit-transition: all 0.3s ease 0s;
	-moz-transition: all 0.3s ease 0s;
}
.more.simple:hover
{
	border-color: #F4BC16;
	background: #F4BC16;
}
.more.small
{
	padding: 12px 19px 11px;
}
/* --- copyright row --- */
.copyright
{
	color: #999;
	font-family: 'Raleway';
	line-height: 24px;
}
.copyright a
{
	color: #444;
}
/* --- prettyPhoto --- */
/*div.pp_default .pp_nav
{
	margin-top: 9px;
}
div.pp_default .pp_close
{
	margin-top: 7px;
}*/
div.pp_default .pp_content_container .pp_left,
div.pp_default .pp_content_container .pp_right,
div.pp_default .pp_top .pp_left,
div.pp_default .pp_top .pp_middle,
div.pp_default .pp_top .pp_right,
div.pp_default .pp_bottom .pp_left,
div.pp_default .pp_bottom .pp_middle,
div.pp_default .pp_bottom .pp_right
{
	background: none;
	padding: 0;
}
div.pp_default .pp_content, div.light_rounded .pp_content
{
	padding: 10px;
}
div.pp_default .pp_description
{
	font-size: 12px;
	color: #7C7C7C;
}
div.pp_default .pp_close
{
	margin-top: 8px;
	background: url("../images/close.png") 0 0 no-repeat;
}
div.pp_default .pp_close:hover
{
	opacity: 1;
	background-position: 0 -30px;
}
/* --- map --- */
.contact-map
{
	width: 100%;
	height: 450px;
}
/* --- slider revolution container --- */
.revolution-slider-container
{
	width: 100%;
	position: relative;
	padding: 0;
	overflow: hidden !important;
}
.revolution-slider
{
	width: 100%;
	height: 600px;
	position: relative;
	background: #F5F5F5;
}
/* --- slider revolution label --- */
.slider-content-box
{
	width: 600px;
	max-height: auto;
	white-space: normal;
	background: rgba(38, 38, 40, 0.7);
	padding-bottom: 47px; 
}
.slider-content-box h2
{
	color: #FFF;
	padding: 21px 30px 50px;
}
.slider-content-box h2 a
{
	color: #FFF;
}
.slider-content-box h2 a:hover
{
	text-decoration: underline;
	opacity: 1;
}
.slider-content-box p
{
	font-family: 'Raleway';
	font-size: 16px;
	color: #FFF;
	line-height: 26px;
	padding: 23px 30px 40px;
}
.slider-content-box .more
{
	margin-left: 30px;
}
.tp-caption a:hover
{
	color: #FFF !important;
}
/* --- slider revolution navigation arrows --- */
.tparrows.preview1
{
	width: 40px;
	height: 100px;
	background: none;
}
.tparrows.preview1::before
{
	content: "";
}
.tparrows.preview1:after
{
	font-family: "template" !important;
	font-size: 24px;
	position: absolute;
	left: 0px;
	top: 0px;
	color: #FFF;
	width: 40px;
	height: 100px;
	text-align: center;
	background: rgba(0, 0, 0, 0.15);
	z-index: 2;
	line-height: 100px;
	-webkit-transition: background 0.2s, color 0.2s;
	-moz-transition: background 0.2s, color 0.2s;
	transition: background 0.2s, color 0.2s;
}
.tp-rightarrow.preview1:after
{
	content: 'a';
	right: 0;
	-moz-transform: scaleX(-1);
	-webkit-transform: scaleX(-1);
	-o-transform: scaleX(-1);
	transform: scaleX(-1);
	-ms-filter: fliph;
	filter: fliph;
}
.tp-leftarrow.preview1:after
{
	content: 'a';
}
.tparrows.preview1:hover:after
{
	background: #FFF;
	color: #666;
}
.tparrows.preview1 .tp-arr-allwrapper
{
	display: none;
}
/* --- slider revolution navigation bullets --- */
.tp-bullets.preview1
{
	height: 12px !important;
}
.tp-bullets.preview1 .tp-bullet
{
	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	width: 11px !important;
	height: 11px !important;
	border: none !important;
	margin-right: 10px !important;
	margin-bottom: 0px !important;
	background: rgba(0, 0, 0, 0.15);
}
.tp-bullets.preview1 .tp-bullet:hover,
.tp-bullets.preview1 .tp-bullet.selected
{
	background: #FFF !important;
	width: 11px !important;
	height: 11px !important;
	border: none !important;
}
/* --- misc --- */
.scroll-top.animated-element
{
	position: fixed;
	visibility: hidden;
	display: block;
	width: 50px;
	padding: 13px 0;
	background: rgba(38,38,40,0.4);
	right: 30px;
	bottom: 30px;
	text-align: center;
	z-index: 2;
}
.scroll-top:before
{
	font-size: 24px;
	color: #FFF;
}
.scroll-top:hover
{
	background: #F4BC16;
}
.header h1 a,
.sf-menu a:hover,
.social-icons a:hover,
.header-top-bar .template-search:hover,
.header-top-bar-container .header-toggle:hover,
.slider-control:hover,
.more:hover,
.vertical-menu li a:hover,
.buttons li a:hover,
.tabs-navigation li a:hover,
.tabs-navigation li a:focus,
.tabs-navigation li a:active,
.taxonomies a:hover,
.pagination li a:hover,
.scroll-top:hover
{
	text-decoration: none;
}
input, textarea
{
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	-webkit-appearance: none;
}
input[type='checkbox']
{
	-webkit-appearance: checkbox;
}
input[type='radio']
{
	-webkit-appearance: radio;
}
:focus
{
	outline:none;
}
::-moz-focus-inner
{
	border:0;
}
body.lock-position
{
	position: fixed;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

/*  custom css */

.know-more {
	color: #9966ff;
	border: 1px solid #9966ff;
	background: none;

}

.box-cls:after,
.why-list list p:after{
	background: none;
}

.new-helmet:before,
.why-list:before{
	content: none !important;
}

.new-helmet p {
	font-size: 17px;
	line-height: 29px;
	word-break: break-word;
}

.brand-list,
.monitor-cls,
.service-real,
.architect-cls,
.architect-cls .row{
	text-align: center;
}

.brand-list li{
	margin: 13px;
	display: inline-block;
	position: relative;
	float: none;
	width: auto;
}

.why-list p {
	color: #606060;
        font-size :19px !important;
}

.why-list li {
	width: 350px;
}

.comp-img li {
	width: 100%;
}

.getknow-cls {
	float: left;
	margin-top: -108px;
        padding: 29px 50px 0;

}

.news-list p {
	padding-bottom: 3px;
	font-size: 14px;
	text-align: justify;
	line-height: 1.8em;
	font-weight: 400;
	color: #606060;
}

.news-list p::after {
	/*background: black;
	height: 1px;
	width: auto;
	margin-bottom: 0; */
}

.news-list a {
	float: left;
	padding: 0px 50px 0;
}

.komp-list li {
	width: 20%;
}

.bold-p {
	font-weight: bold;
}

.track-cls p {
	color: black;
	display: inline-block;
	padding: 0;
	margin: 0;
}

.power-list li {
	float: none;
}

.power-list li p {
	width: 65%;
}

.architect-cls p {
	width: 60%;
	display: inline-block;
	font-size: 17px;
}

.architect-cls h2 {
	font-size: 20px;
}

.global-list li {
	width: 30%;
}

.global-list .post-content {
	width: 225px !important;
	text-align: left;
	float: left;
}

.global-list .post-image {
	text-align: left;
	float: left;
}

.global-list p {
	padding: 0px !important;
}

.achievement-section {
	background: aliceblue;
	padding-top: 25px;
}

.achieve-list li {
	height: 260px;
	margin-right: 0px;
	border: 1px solid blue;
	background: white;
	width: 250px;
	margin-right: 27px;
	margin-left: 61px;
}

.achieve-list p {
	font-size: 14px;
	padding: 0px !important;
}

.desk-bg {
	background: url("../images/Desktop/Desktop Image-02.png") 0 0 no-repeat;
	background-size: cover;
}

.desk-bg-08 {
      background: url("../images/Desktop/Desktop Image-08.png") 0 0 no-repeat;
      background-size: cover;
}

.desk-bg-08 h2 {
   width:auto;
}

.green-text p,
.green-text{
	color: #66ffa2;
}

.arch-dropdown,
.service-real li,
.services-real .para-content p{
	display: inline-block;
}
.mon-ul span,
.desk-bg-08 h2,
.social-icons.purple li a,
.vision-cls p,
.service-real h2,
.para-content p{
	color: #FFFFFF;
}

.mon-ul span {
	display: block;
}

.para-content p,
.monitor-cls h2{
	width: 70%;
	display: inline-block;
}

.komp-list img {
	width: 65px;
}

.architect-cls img {
	width: 65%;
}

.kompImp {
    width:313px;
}

.komp-span {
    font-size: 20px;
    padding-right: 57px;
}

.cursor {
 cursor :pointer;
}
