.animated-element
{
	visibility: hidden;
	position: relative;
}
.fadeIn, .fadeOut, .scale, .slideRight, .slideRightBack, .slideRight25, .slideRight200, .slideLeft, .slideLeftBack, .slideLeft50, .slideDown, .slideDownBack, .slideDown200, .slideUp, .slideUpBack, .width, .height
{
	animation-timing-function: cubic-bezier(.75, 0, .25, 1);	
	-webkit-animation-timing-function: cubic-bezier(.75, 0, .25, 1);		
	visibility: visible !important;	
}
.slideRightBack, .slideLeftBack, .slideDownBack, .slideUpBack, .fadeOut
{
	opacity: 0;
}
/* --- fadeIn --- */
.fadeIn
{	
	animation-name: fadeIn;
	-webkit-animation-name: fadeIn;	
}
@keyframes fadeIn 
{
	0% 
	{
		opacity: 0;
	}
	100% 
	{
		opacity: 1;
	}
}
@-webkit-keyframes fadeIn 
{
	0% 
	{
		opacity: 0;
	}
	100% 
	{
		opacity: 1;
	}
}
/* --- fadeOut --- */
.fadeOut
{	
	animation-name: fadeOut;
	-webkit-animation-name: fadeOut;	
}
@keyframes fadeOut 
{
	0% 
	{
		opacity: 1;
	}
	100% 
	{
		opacity: 0;
	}
}
@-webkit-keyframes fadeOut 
{
	0% 
	{
		opacity: 1;
	}
	100% 
	{
		opacity: 0;
	}
}
/* --- scale --- */
.scale
{
	animation-name: scale;
	-webkit-animation-name: scale;
}
@keyframes scale 
{
	0% 
	{
		transform: scale(0.2);	
	}
	100% 
	{
		transform: scale(1);	
	}		
}
@-webkit-keyframes scale 
{
	0% 
	{
		-webkit-transform: scale(0.2);	
	}
	100% 
	{
		-webkit-transform: scale(1);
	}		
}
/* --- slideRight --- */
.slideRight
{
	animation-name: slideRight;
	-webkit-animation-name: slideRight;	
}
@keyframes slideRight 
{
	0% 
	{
		opacity: 0;
		transform: translateX(-100%);
	}
	100% 
	{
		opacity: 1;
		transform: translateX(0%);
	}
}
@-webkit-keyframes slideRight 
{
	0% 
	{
		opacity: 0;
		-webkit-transform: translateX(-100%);
	}
	100% 
	{
		opacity: 1;
		-webkit-transform: translateX(0%);
	}
}
/* --- slideRightBack --- */
.slideRightBack
{
	animation-name: slideRightBack;
	-webkit-animation-name: slideRightBack;	
}
@keyframes slideRightBack 
{
	0% 
	{
		opacity: 1;
		transform: translateX(0%);
	}
	100% 
	{
		opacity: 0;
		transform: translateX(-100%);
	}
}
@-webkit-keyframes slideRightBack 
{
	0% 
	{
		opacity: 1;
		-webkit-transform: translateX(0%);
	}
	100% 
	{
		opacity: 0;
		-webkit-transform: translateX(-100%);
	}
}
/* --- slideRight25 --- */
.slideRight25
{
	animation-name: slideRight25;
	-webkit-animation-name: slideRight25;	
}
@keyframes slideRight25
{
	0% 
	{
		opacity: 0;
		transform: translateX(-25%);
	}
	100% 
	{
		opacity: 1;
		transform: translateX(0%);
	}
}
@-webkit-keyframes slideRight25
{
	0% 
	{
		opacity: 0;
		-webkit-transform: translateX(-25%);
	}
	100% 
	{
		opacity: 1;
		-webkit-transform: translateX(0%);
	}
}
/* --- slideRight200 --- */
.slideRight200
{
	animation-name: slideRight200;
	-webkit-animation-name: slideRight200;	
}
@keyframes slideRight200 
{
	0%
	{
		opacity: 0;
		transform: translateX(-200%);
	}
	100% 
	{
		opacity: 1;
		transform: translateX(0%);
	}
}
@-webkit-keyframes slideRight200 
{
	0%
	{
		opacity: 0;
		-webkit-transform: translateX(-200%);
	}
	100% 
	{
		opacity: 1;
		-webkit-transform: translateX(0%);
	}
}
/* --- slideLeft --- */
.slideLeft
{
	animation-name: slideLeft;
	-webkit-animation-name: slideLeft;
}
@keyframes slideLeft 
{
	0% 
	{
		opacity: 0;
		transform: translateX(100%);
	}
	100% 
	{
		opacity: 1;
		transform: translateX(0%);
	}	
}
@-webkit-keyframes slideLeft 
{
	0% 
	{
		opacity: 0;
		-webkit-transform: translateX(100%);
	}
	100% 
	{
		opacity: 1;
		-webkit-transform: translateX(0%);
	}
}
/* --- slideLeftBack --- */
.slideLeftBack
{
	animation-name: slideLeftBack;
	-webkit-animation-name: slideLeftBack;
}
@keyframes slideLeftBack 
{
	0% 
	{
		opacity: 1;
		transform: translateX(0%);
	}
	100% 
	{
		opacity: 0;
		transform: translateX(100%);
	}	
}
@-webkit-keyframes slideLeftBack 
{
	0% 
	{
		opacity: 1;
		-webkit-transform: translateX(0%);
	}
	100% 
	{
		opacity: 0;
		-webkit-transform: translateX(100%);
	}
}
/* --- slideLeft50 --- */
.slideLeft50
{
	animation-name: slideLeft50;
	-webkit-animation-name: slideLeft50;
}
@keyframes slideLeft50 
{
	0% 
	{
		opacity: 0;
		transform: translateX(50%);
	}
	100% 
	{
		opacity: 1;
		transform: translateX(0%);
	}	
}
@-webkit-keyframes slideLeft50 
{
	0% 
	{
		opacity: 0;
		-webkit-transform: translateX(50%);
	}
	100% 
	{
		opacity: 1;
		-webkit-transform: translateX(0%);
	}
}
/* --- slideDown --- */
.slideDown
{
	animation-name: slideDown;
	-webkit-animation-name: slideDown;				
}
@keyframes slideDown 
{
	0% 
	{
		opacity: 0;
		transform: translateY(-100%);
	}
	100% 
	{
		opacity: 1;
		transform: translateY(0%);
	}		
}
@-webkit-keyframes slideDown 
{
	0% 
	{
		opacity: 0;
		-webkit-transform: translateY(-100%);
	}
	100% 
	{
		opacity: 1;
		-webkit-transform: translateY(0%);
	}	
}
/* --- slideDownBack --- */
.slideDownBack
{
	animation-name: slideDownBack;
	-webkit-animation-name: slideDownBack;				
}
@keyframes slideDownBack 
{
	0% 
	{
		opacity: 1;
		transform: translateY(0%);
	}
	100% 
	{
		opacity: 0;
		transform: translateY(-100%);
	}		
}
@-webkit-keyframes slideDownBack 
{
	0% 
	{
		opacity: 1;
		-webkit-transform: translateY(0%);
	}
	100% 
	{
		opacity: 0;
		-webkit-transform: translateY(-100%);
	}	
}
/* --- slideDown --- */
.slideDown200
{
	animation-name: slideDown200;
	-webkit-animation-name: slideDown200;				
}
@keyframes slideDown200
{
	0% 
	{
		opacity: 0;
		transform: translateY(-100%);
	}
	100% 
	{
		opacity: 1;
		transform: translateY(0%);
	}		
}
@-webkit-keyframes slideDown200
{
	0% 
	{
		opacity: 0;
		-webkit-transform: translateY(-100%);
	}
	100% 
	{
		opacity: 1;
		-webkit-transform: translateY(0%);
	}	
}
/* --- slideUp --- */
.slideUp
{
	animation-name: slideUp;
	-webkit-animation-name: slideUp;
}

@keyframes slideUp 
{
	0% 
	{
		opacity: 0;
		transform: translateY(100%);
	}
	100%
	{
		opacity: 1;
		transform: translateY(0%);
	}	
}

@-webkit-keyframes slideUp 
{
	0% 
	{
		opacity: 0;
		-webkit-transform: translateY(100%);
	}
	100% 
	{
		opacity: 1;
		-webkit-transform: translateY(0%);
	}	
}
/* --- slideUpBack --- */
.slideUpBack
{
	animation-name: slideUpBack;
	-webkit-animation-name: slideUpBack;
}

@keyframes slideUpBack 
{
	0% 
	{
		opacity: 1;
		transform: translateY(0%);
	}
	100%
	{
		opacity: 0;
		transform: translateY(100%);
	}	
}

@-webkit-keyframes slideUpBack 
{
	0% 
	{
		opacity: 1;
		-webkit-transform: translateY(0%);
	}
	100% 
	{
		opacity: 0;
		-webkit-transform: translateY(100%);
	}	
}
/* --- width --- */
.width
{
	animation-name: width;
	-webkit-animation-name: width;
}

@keyframes width 
{
	0% 
	{
		width: 0%;
	}
	100%
	{
		width: 100%;
	}	
}

@-webkit-keyframes width 
{
	0% 
	{
		width: 0%;
	}
	100% 
	{
		width: 100%;
	}	
}
/* --- height --- */
.height
{
	animation-name: height;
	-webkit-animation-name: height;
}

@keyframes height 
{
	0% 
	{
		height: 0%;
	}
	100%
	{
		height: 100%;
	}	
}

@-webkit-keyframes height 
{
	0% 
	{
		height: 0%;
	}
	100% 
	{
		height: 100%;
	}	
}