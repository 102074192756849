@font-face {
    font-family: "Bariol";
    src: url("../fonts/Bariol_Regular.otf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Bariol-b";
    src: url("../fonts/bariol_bold-webfont.ttf");
    font-weight: normal;
    font-style: normal;
}

.u-logo {
    background: url("../images/logos/h-logo.png");
    width: 50%;
    height: 50px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.slider-content-box {
    background: url("../images/Desktop/Desktop Image-22.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.u-logo-w {
    background: url("../images/logos/logo-w.png");
    height: 75px;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
    padding:5px;
}

.ut li a {
    color: #9966ff !important;
    font-size: 1.25rem;
}

.ut li.selected a {
    color: #330099 !important;
}

.ut li.demo-Link a {
    color: black !important;
}

h4 {
    font-size: 1.5rem;
}

.f-24 {
    font-size: 1.5rem;
}

.trust-img {
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
    width: 10vw;
    height: 18vh;
    display: flex;
}

.img-total {
    background: url(../images/trust/total.png);
}

.img-bac {
    background: url(../images/trust/bac.png);
}

.img-cb {
    background: url(../images/trust/cb.png);
}

.img-indo {
    background: url(../images/trust/indo.png);
}

.img-deep {
    background: url(../images/trust/deep.png);
}

.img-kenna {
    background: url(../images/trust/kenna.png);
}

.img-lt {
    background: url(../images/trust/LT.png);
}

.img-mark {
    background: url(../images/trust/mark.png);
}

.img-venus {
    background: url(../images/trust/venus.png);
}

.y-img {
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
    height: 16vh;
}

.nw-img {
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: cover !important;
    margin: 0 3rem;
    height: 34vh;
}

.img-y-vt-1 {
    background: url(../images/ut/y-vt-1.png);
}

.img-y-vt-2 {
    background: url(../images/ut/y-vt-2.png);
}

.img-y-vt-3 {
    background: url(../images/ut/y-vt-3.png);
}

.img-nw-1 {
    background: url(../images/ut/nw1.png);
}

.img-nw-2 {
    background: url(../images/ut/nw2.png);
}

.img-nw-3 {
    background: url(../images/ut/nw3.png);
}

.img-kompress {
    background: url(../images/ut/kompress.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 50vh;
    width: 28vw;
}

.footer {
    background: #3b0099;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-section {
    width: 1170px;
}

.f-mrg {
    margin: 3rem 0;
}

.d-flex {
    display: flex;
}

.align-center {
    align-items: center;
}

.logo-sec {
    flex: 2;
    display: flex;
    flex-direction: column;
    padding-right: 5rem;
}

.sub-sec {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.c-white {
    color: white;
}

.pr-_5 {
    padding-right: 0.5rem;
}

.pd-h_5 {
    padding: 0.5rem;
}

.fx-1{
    flex: 1;
}

.fx-2{
    flex: 2;
}

.br-1-wt{
    border-right: 1px solid white;
}

.bd-1-wt{
    border-bottom: 1px solid white;
}

.fs-14{
    font-size: 14px;
}

.fs-18{
    font-size: 18px;
}

.fs-20{
    font-size: 20px;
}

.fs-24 {
    font-size: 24px;
}

.pd-v_25 {
    padding: 0.25rem 0;
}

.mr-1{
    margin-right: 1rem;
}

.mt-1{
    margin-top: 1rem;
}

.mt-2{
    margin-top: 2rem;
}

.mt-3 {
    margin-top: 3rem;
}

.kompress-img p{
    width: 57%;
    text-align: left;
}

.ft-inp {
    background: transparent;
    border-radius: 3rem;
    border: 1px solid #9966ff;
    height: 1.25rem;
    padding: 0.5rem 0.75rem;
    color: white;
    font-size: 0.8rem;
    font-family: "Bariol";
    width: 55%;
}

.ft-btn{
    border: 1px solid #9966ff;
    background: #9966ff;
    border-radius: 3rem;
    padding: 0.5rem 1.5rem;
    color: white;
}

.monitor-list li {
    width: 16%;
}

.arch-dropdown .ui-selectmenu-button{
    width: 231px !important;
    border-radius: 38px;
    border: 1px solid gray;
    background: #F5F5F5;
}

.arch-dropdown .ui-selectmenu-button .ui-icon {
    padding-top: 4px;
    color: #90D93F;
    border: none;
}

.arch-dropdown .ui-selectmenu-button span.ui-selectmenu-text {
    line-height: 4px;
}

.purple-text,
.core-name h2,
.adv-name p {
    color: #a266ff;
}

.purple-text {
    word-break: break-word;
    width: 55%;
    display: inline-block;
}


.ai-text {
    width: 78%;
    font-size: 18px;
    font-family: 'Bariol';
    display: inline-block;
}

.core-name h2 {
    font-size: 19px;
    text-align: left;
    font-weight: 300;
}

.core-name h2::after {
    color: #ffff;
    margin-top: 7px;
    line-height: 16px;
    padding-left: 7px;
    content: 'In';
    float: right;
    padding-top: 5px;
    height: 20px;
    width: 20px;
    background-color: #e0ccff;
    border-radius: 50%;
    display: inline-block;
}

.core-name {
    border-top: 2px solid #ffff;
    border-bottom: 2px solid #ffff;
    margin-bottom: 10px;
}

.key-cls img {
    width: 50%;
    display: block;
    padding: 17px;
}
.key-cls {
    border: none !important;
}

.key-cls p {
    line-height: 16px;
    font-size: 16px;
    text-align: left;
    padding-left: 17px !important;
    padding-right: 10px !important;
    font-family: 'Bariol';
    padding-top: 0px;
}

.kompress-sec {
        background: linear-gradient(90deg, #dbfadb 50%, #ebffeb 50%);
}

.kompress-btn {
    background: #dbfadb;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.kompress-img {
    background: #ebffeb;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.kom-btn {
    font-size: 1.5rem;
    width: 40%;
    text-align: left;
    border: 1px solid #53e453;
    border-radius: 2rem;
    padding: 1px 17px;
    margin: 1rem 0;
}

.kom-btn-selected {
    background: white;
    border-color: white !important;
}

.blue-btn {
    font-size: 1rem;
    width: 35%;
    border: 1px solid #9966ff;
    color: #9966ff;
    border-radius: 2rem;
    padding: 0.5rem 1.5rem;
    margin: 1rem 0;
}

.blue-btn-selected {
    background: #9966ff;
    color: white !important;
}

.p-news {
    height: 10vh;
}

.pointer {
    cursor: pointer;
    color: #a266ff;
    font-size: 1rem;
}

.login-bg {
    background: url(../images/ut/login-bg.png);
    height: calc(100vh - 100px);
    width: auto;
}

.signup-bg {
    background: url(../images/ut/login-bg.png);
    width: auto;
}

.signup-pad {
    padding-top: 62px;
    padding-bottom: 62px;

}

.login-fade {
   /* height: 100%;
    background: #DBFADA; */
    opacity: 1;
    width: 100%;
}

.justify-center{
    justify-content: center;
}

.login-container {
    width: 1170px;
}

.img-circle{
    background:url(../images/ut/circle.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 10vh;
    width: 6vw;
}

.login-p1{
    font-size: 1.5rem;
    color: black;
    text-align: left;
}

.lg-ct-1{
    margin: 2rem;
}

.lg-p2{
    font-size: 2rem;
    color: #9966ff;
    text-align: left;
}

.login-p3{
    font-size: 1.5rem;
    color: black;
    text-align: left;
    padding-bottom: 0px;
}

.login-p4{
    font-size: 1.2rem;
    color: #444;
    text-align: left;
}

.lg-fm-bg{
    background-color: white;
    min-height: 100px;
    border: 1px solid white;
    border-radius: 16px;
    width: 100%;
    padding: 2rem; 
}

.lg-ip{
    width: 85%;
    background: transparent;
    border: 0px;
    border-bottom: 1px solid;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    font-family: 'Bariol';
    color: #9966ff;
}

.lg-btn{
    cursor: pointer;
    background: #9966ff;
    border: 1px solid #9966ff;
    border-radius: 2rem;
    color: white;
    font-size: 1.5rem;
    padding: 0.25rem 3rem;
}

.lg-p{
    color: #9966ff;
    font-size: 1rem;
    padding-bottom: 0px;
}


.pointer {
    padding: 7px 0px 0px 0px !important;
}

.read-more li{
    width: 250px;
    margin-left: 50px;
    margin-right: 45px;
}

.read-more .nw-img {
    margin: 0px;
}

.p-news {
    height: 50px;
    padding-left: 0px !important;
    padding-right: 0px!important;
    border-bottom: 1px solid black;
    padding-bottom: 30px !important;
}

.top-img {
    padding-right: 238px;
    width: 17%;
    float: right;
    display: block;

}

.bot-img {

    display: block;
    width: 54%;

}

.box-cls {
   
    font-weight: 300;
    font-size: 28px;
    color: #606060;
    line-height: 0px;
    padding: 0px 50px 0;
    font-family: 'Bariol';

}

.marg-top-neg {
    margin-top: -176px;
}

.social-icons.purple li {
    border: 1px solid #9966ff;
    background: #9966ff;
    border-radius: 17rem;
    margin-right: 10px;

}

.margin-right-15 {
   margin-right :15px;
}

.bab-font P{
  font-family: 'Bariol';
  font-size: 24px;
}

.bab-font p {
   padding :0;

}

.font-17 {
   
   font-size : 17px;

}

.architect-cls .fnt-24,
.fnt-24 {
   font-size :24px;
}

.p-2,
.architect-cls .p-2 {
    width: 66%;
    display: inline-block;
    font-size: 21px;

}

.ft-size {
    font-size: 19px;
    font-family: 'Bariol';
    padding: 0.5rem 3.5rem;
}

.energy-font {
    font-size :25px;
    font-family: 'Bariol';
    font-weight: 400;
    padding-bottom: 20px
}

.green-bariol {

   font-weight: 300;
    font-size: 28px;
    color: #66ffa2;
    line-height: 0px;
    padding: 0px 50px 0;
    font-family: 'Bariol';
}

.dgreen-bar {
    font-weight: 300;
    font-size: 22px;
    color: #66e766;
    line-height: 0px;
    padding-bottom: 19px;
    font-family: 'Bariol';
}

.wht-bariol {
    font-size: 19px;
    font-family: 'Bariol';
    color: #ffff;
    width: 57%;
    display: inline-block;

}

.wd-70 {
 width:70% !important;
 color: #e3e3e3 !important;
}


.monitor-list img {
    width :91px;
}

.monitor-list span {
    font-weight: 400;
    font-family: 'Bariol';
    font-size: 17px;

}

.pwr-padd {
    padding: 16px 50px 0;
    font-weight: 400;
}

.power-list img {
   width :50%;
}

.adv-name p {
    font-family: 'Bariol';
    font-size: 19px;
    font-weight: 600;
}
.global-list img {
    width:76px;
}

.pdb-70 {

  padding-bottom : 30px !important;
}

.core-title {
    font-weight: 300;
    font-size: 28px;
    color: #606060;
    line-height: 0px;
    font-family: 'Bariol';
}



.key-link a {
    display: block;
    font-family: 'Bariol';
    font-size: 16px;
    color: #444444;
    line-height: 21px;
    padding-left: 17px !important;
    padding-right: 10px !important;
    text-align: left;
}


.slider-img {
  width:150px !important;
  height:150px !important;
  float:right;
  padding:25px !important;
}
