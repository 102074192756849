.loading {
    position: fixed;
    z-index: 1000000;
    height: 100%;
    width: 100%;
    display: table;
    background-color: rgb(0, 0, 0, 0.2);
}

.loading-intermediate {
    display: table-cell;
    vertical-align: middle;
}

.loading-icon {
    background: url("../img/load.gif") no-repeat center center;
    background-size: contain;
    display: table-cell;
    height: 40vh;
    width: 100vw;
}

.display-none {
    display: none;
}

.MuiFormControl-marginNormal{
    margin: 10px 0 0 0;
}

.mrg-top-10{
    margin-top: 10px !important;
}

.container {
    width: 100%;
    float: left;
}

.wrapper {
    width: 45%;
    float: left;
}

.subHeading {
    font-size: 11pt;
}

.boldContent {
    font-size: 13pt;
    font-weight: 800;
}

/* table css */

.table{
    background-color: transparent;
}

.table thead {
    background-color: #f5f5f5;
    border-right: 1px solid #eaeaea;
}

.table thead tr th{
    font-size: 1rem;
    font-weight: 800;
    padding: 12px 8px;
    font-family: "Roboto",
    "Helvetica",
    "Arial",
    sans-serif;
}

.table tbody tr td{
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1;
    font-family: "Roboto",
    "Helvetica",
    "Arial",
    sans-serif;
    vertical-align: middle;
}

/* table css end here */

.customTable{
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    border-spacing: 0;
    border-collapse: collapse;
    background-color: transparent;
    display: table;
    height: 20vh;
    overflow-y: auto;
}

.customTable thead{
    color: #26c6da;
    display: table-header-group;
    font-size: .875rem;
}

.customTable thead tr{
    color: inherit;
    height: 56px;
    display: table-row;
    outline: none;
    vertical-align: middle;
    border-bottom:1px solid;
    border-bottom-color: rgb(224,224,224) 
}

.customTable thead tr th{
    font-size: 1em;
    font-weight: bold;
    border-right: 1px solid #eaeaea;
    background-color: #f5f5f5;
    padding: 12px 8px;
    text-align: left;
	width: 20%;
}

.customTable tbody{
    display: table-row-group;
}

.customTable tbody tr{
    color: inherit;
    height: 48px;
    display: table-row;
    outline: none;
    vertical-align: middle;
    border-bottom:1px solid;
    border-bottom-color: rgb(224, 224, 224);	
}

.customTable tbody tr td{
    padding: 2px 2px;
    font-size: 0.8125rem;
    font-family: "Roboto",
    "Helvetica",
    "Arial",
    sans-serif;
    font-weight: 300;
    line-height: 1;
    vertical-align: middle;
	width: 23.4%;
}

.hyperlink{
    cursor: pointer;
    color: #26c6da;
}

.blink_me {
    animation: blinker 1s linear infinite;
    color: #ff1744;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.watermark{
    font-weight: 600;
    font-size: 12px;
    padding-right: 15px;
    position: absolute;
    right: 0;
    bottom: 0px;
    z-index: 1;
}

.form-check-input{
    position: relative !important;
}

.react-bootstrap-table th[data-row-selection]{
    width: 40px;
}

.width_100{
    width: 100%;
}

.show_more {
    float: right;
    color: #26c6da;
    cursor: pointer;
}

.MuiSnackbarContent-message span{
    font-weight: 500;
}

.ver-cen-content {
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    height: 100%;
}

.txt-col-blk{
    color: #000;
}

.float_rght{
    float: right;
}

.scrollBar {
	height: 600px;
	/*overflow-y: auto;*/
	width: 50%;
}

.gridItemPadding {
	padding-left: 10px;
}

.thead {
  display: table !important; /* to take the same width as tr */
  width: calc(100% - 17px); /* - 17px because of the scrollbar width */
}

.tbody {
  display: block !important; /* to enable vertical scrolling */
  max-height: 500px; /* e.g. */
  overflow-y: scroll; /* keeps the scrollbar even if it doesn't need it; display purpose */
}

.header {
	padding:10px;
	float:left;
	width:100%;
}
.status_container {
	width:100%;
	float:left;
}

.react-bootstrap-table-editing-cell {
	width: 60px !important;
}