.dash-icon{
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
}

.no-break{
    word-break: keep-all;
}

.icon-container{
    margin-right: 5px !important;
}